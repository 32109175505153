<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <p class="title">Revenue Totals</p>
        <subtitle-totals :labels="['Adjust Total', 'GST']"
          :totals="[totalExGst, innerValue.gst]"
          :shows="[true, showGst]" />
      </div>
      <div v-show="innerValue.applyToInvoiceType === invoiceType.Quote || innerValue.applyToInvoiceType === invoiceType.Gst"
        class="column">
        <div v-if="!gstPaid && !readOnlyView"
          class="column field is-pulled-right">
          <p class="buttons">
            <button
              class="button is-primary tooltip is-tooltip-topright"
              data-tooltip="Add blank item"
              @click="addBlankItem">
              <span class="icon">
                <i class="mdi mdi-shape-square-plus" />
              </span>
            </button>
          </p>
        </div>
      </div>
    </div>

    <div v-show="innerValue.hasGstInvoice"
      class="field">
      <label class="label">Quote Invoice <span> {{ innerValue.adjustQuoteInvoiceNo }} </span></label>
    </div>
    <div>
      <table class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Account</th>
            <th class="has-text-right">Amount</th>
            <th class="has-text-right">New Value</th>
            <th class="has-text-right">Adjust Value</th>
            <th>Remark</th>
            <th v-if="!gstPaid && !readOnlyView" />
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th class="has-text-left">Adjust Total</th>
            <th colspan="5"
              class="has-text-left">{{ innerValue.totalExGst | formatCurrency($userInfo.locale) }}</th>
          </tr>
          <tr v-show="!innerValue.hasGstInvoice && !innerValue.gstItem.isDeleted">
            <th class="has-text-left">GST</th>
            <th colspan="5"
              class="has-text-left">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th>
          </tr>
        </tfoot>
        <tbody v-if="gstPaid || readOnlyView">
          <tr
            v-for="(item) in activeItems"
            :key="item.invoiceItemId">
            <td>{{ item.glAccountName }}</td>
            <td class="has-text-right">{{ item.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ item.newValue | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ item.totalExGst | formatNumber($userInfo.locale) }}</td>
            <td>{{ item.invoiceItemDesc }}</td>
          </tr>
          <tr v-if="!innerValue.hasGstInvoice && !innerValue.gstItem.isDeleted">
            <td>{{ innerValue.gstItem.glAccountName }}</td>
            <td class="has-vertical-middle has-text-right">{{ innerValue.gstItem.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td class="has-vertical-middle has-text-right">
              {{ innerValue.gstItem.newValue | formatNumber($userInfo.locale) }}
            </td>
            <td class="has-vertical-middle has-text-right">
              {{ innerValue.gstItem.totalExGst | formatNumber($userInfo.locale) }}
            </td>
            <td>{{ innerValue.gstItem.invoiceItemDesc }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            v-for="(item, index) in activeItems"
            :key="item.invoiceItemId">
            <td v-if="item.isNew && item.unitPrice === 0 && innerValue.applyToInvoiceType != invoiceType.Excess"
              class="account-input">
              <multiselect
                v-if="glAccountListComboFilter && selectedItemAccounts.length > 0"
                v-model="selectedItemAccounts[index]"
                :options="glAccountListComboFilter"
                placeholder="Select account"
                label="displayName"
                track-by="accountNo"
                deselect-label="Can't remove this value"
                :show-labels="false"
                :allow-empty="false">
                <span
                  class="has-text-danger"
                  slot="noResult">G/L account not found.</span>
              </multiselect>
            </td>
            <td v-else>{{ item.glAccountName }}</td>
            <td class="has-vertical-middle has-text-right">{{ item.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right money-input">
              <vue-numeric
                class="input has-text-right"
                v-model.number="item.newValue"
                :minus="true"
                :precision="2"
                @value-changed="updateNewValue(item)" />
            </td>
            <td class="has-text-right money-input">
              <vue-numeric
                class="input has-text-right"
                :minus="true"
                :precision="2"
                v-model.number="item.totalExGst"
                @value-changed="updateAdjustValue(item)" />
            </td>
            <td>
              <input class="input"
                v-model="item.invoiceItemDesc"
                type="text"
                placeholder="Remark">
            </td>
            <td v-if="item.unitPrice === 0"
              class="has-vertical-middle has-text-centered is-content-width">
              <a
                class="button is-danger is-small is-inverted"
                @click="deleteItem(item, index)">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
            <td v-else />
          </tr>
          <tr v-if="!innerValue.hasGstInvoice && !innerValue.gstItem.isDeleted">
            <td>{{ innerValue.gstItem.glAccountName }}</td>
            <td class="has-vertical-middle has-text-right">{{ innerValue.gstItem.unitPrice | formatNumber($userInfo.locale) }}</td>
            <td v-if="editGst"
              class="has-text-right money-input">
              <vue-numeric
                class="input has-text-right"
                v-model.number="innerValue.gstItem.newValue"
                :minus="true"
                :precision="2"
                @value-changed="updateNewValue(innerValue.gstItem)" />
            </td>
            <td v-else
              class="has-vertical-middle has-text-right">
              {{ innerValue.gstItem.newValue | formatNumber($userInfo.locale) }}
            </td>
            <td v-if="editGst"
              class="has-text-right money-input">
              <vue-numeric
                class="input has-text-right"
                v-model.number="innerValue.gstItem.totalExGst"
                :minus="true"
                :precision="2"
                @value-changed="updateAdjustValue(innerValue.gstItem)" />
            </td>
            <td v-else
              class="has-vertical-middle has-text-right">
              {{ innerValue.gstItem.totalExGst | formatNumber($userInfo.locale) }}
            </td>
            <td>
              <input class="input"
                v-model="innerValue.gstItem.invoiceItemDesc"
                type="text"
                placeholder="Remark">
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
    <div class="column" />
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import VueNumeric from '@/components/VueNumeric'
import Multiselect from 'vue-multiselect'
import SubtitleTotals from '@/components/SubtitleTotals'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { calcGst, roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { InvoiceTypes, GlCategoryTypes } from '@/enums'
import { InvoiceItemModel } from '@/classes/viewmodels'
import QuickInvoiceService from '@/views/quickinvoice/QuickInvoiceService'
import StoreMixin from './storeMixin'
import { EventHubTypes } from '@/enums'

export default {
  name: 'DebtorAdjustmentInvoice',
  components: {
    Multiselect,
    VueNumeric,
    SubtitleTotals
  },
  mixins: [NumberFiltersMixin, StoreMixin],
  props: {
    value: null,
    gstPaid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      glAccountListCombo: [],
      selectedItemAccounts: [],
      availableGlAccounts: []
    }
  },
  computed: {
    editGst() {
      if (this.innerValue.applyToInvoiceType === InvoiceTypes.Quote || this.innerValue.applyToInvoiceType === InvoiceTypes.Gst)
      {
        return true
      }
      else
        return false
    },
    showGst() {
      if (!this.innerValue.gstItem.isDeleted)
      {
        return true
      }
      else
      {
        return false
      }
    },
    activeItems() {
      return this.innerValue.items.filter(i => !i.isDeleted)
    },
    glAccountListComboFilter() {
      return this.glAccountListCombo.filter(gl => !this.activeItems.find(rm => (rm.invoiceGlCode === gl.accountNo)))
    },
    colGst() {
      return calcGst(1, this.totalExGst, this.innerValue.gstRate)
    },
    totalExGst() {
      return roundAwayFromZero(
          this.activeItems.filter(i => !i.isDeleted).reduce(function(total, item) {
            return total + item.totalExGst
          }, 0),
          2
        )
    },
    totalIncGst() {
      return roundAwayFromZero(this.totalExGst + this.innerValue.gst)
    },
    invoiceType() {
      return InvoiceTypes
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$eventHub.$emit(EventHubTypes.ItemValueChanged)
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedItemAccounts: {
      handler: function(newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          const vm = this
          newVal.forEach(function(account, index) {
            if (account) {
              vm.innerValue.items[index].glAccountName = account.displayName
              vm.innerValue.items[index].invoiceGlCode = account.accountNo
              vm.innerValue.items[index].glCategory = account.glCategory
            }
          })
        }
      }
    }
  },
  async created() {
    if (this.innerValue.applyToInvoiceType === InvoiceTypes.Quote || this.innerValue.applyToInvoiceType === InvoiceTypes.Gst)
    {
      this.glAccountListCombo = await QuickInvoiceService.getGlAccountDropdown()
      this.generateSelectedAccounts()
    }
  },
  mounted() {},
  methods: {
    updateNewValue: _debounce(function(item) {
      item.totalExGst = roundAwayFromZero(item.newValue - item.unitPrice, 2)
      item.gst = calcGst(1, item.totalExGst, this.innerValue.gstRate)
      item.totalIncGst = roundAwayFromZero(item.totalExGst + item.gst, 2)

      this.innerValue.totalExGst = this.totalExGst
      this.innerValue.totalIncGst = this.totalIncGst

      if (!this.innerValue.deleted && item.glCategory !== GlCategoryTypes.GST_COL && !this.innerValue.gstItem.isDeleted)
      {
          this.innerValue.gst = this.colGst
          this.innerValue.gstItem.gst = calcGst(1, this.colGst, this.innerValue.gstRate)
          this.innerValue.gstItem.newValue = this.colGst ===  0 ? this.innerValue.gstItem.unitPrice : roundAwayFromZero(this.innerValue.gstItem.unitPrice + this.colGst, 2)
          this.innerValue.gstItem.totalExGst = this.colGst
          this.innerValue.gstItem.totalIncGst = roundAwayFromZero(this.innerValue.gstItem.totalExGst + this.innerValue.gstItem.gst, 2)
      }
    }, 300),
    updateAdjustValue: _debounce(function(item) {
      item.newValue = item.totalExGst === 0 ? item.unitPrice : roundAwayFromZero(item.unitPrice + item.totalExGst, 2)
      item.gst = calcGst(1, item.totalExGst, this.innerValue.gstRate)
      item.totalIncGst = roundAwayFromZero(item.totalExGst + item.gst, 2)

      this.innerValue.totalExGst = this.totalExGst
      this.innerValue.totalIncGst = this.totalIncGst

      if (!this.innerValue.deleted && item.glCategory !== GlCategoryTypes.GST_COL && !this.innerValue.gstItem.isDeleted)
      {
          this.innerValue.gst = this.colGst
          this.innerValue.gstItem.gst = calcGst(1, this.colGst, this.innerValue.gstRate)
          this.innerValue.gstItem.newValue = this.colGst ===  0 ? this.innerValue.gstItem.unitPrice : roundAwayFromZero(this.innerValue.gstItem.unitPrice + this.colGst, 2)
          this.innerValue.gstItem.totalExGst = this.colGst
          this.innerValue.gstItem.totalIncGst = roundAwayFromZero(this.innerValue.gstItem.totalExGst + this.innerValue.gstItem.gst, 2)
      }
    }, 300),
    addBlankItem() {
      var accNo = '41210'
      if (this.activeItems.find(i => i.invoiceGlCode === '41210'))
      {
        accNo = this.glAccountListComboFilter[0].accountNo
      }
      const newItem = new InvoiceItemModel(this.$userInfo.companyId, this.innerValue.invoiceId, accNo)
      // var newItem = {
      //   companyId: this.$userInfo.companyId,
      //   costCentre: null,
      //   deleted: false,
      //   invoiceGlCode: '41210', // TODO: Should not be hard coded
      //   glCategory: '',
      //   invoiceId: this.invoiceId,
      //   invoiceItemDesc: '',
      //   invoiceItemId: Guid.newGuid(),
      //   isDeleted: false,
      //   isNew: true,
      //   quantity: 1,
      //   quoteItemId: null,
      //   totalExGst: 0,
      //   gst: 0,
      //   totalIncGst: 0,
      //   unitPrice: 0,
      //   unitPriceIncGst: 0
      // }
      this.innerValue.items.push(newItem)
      this.generateSelectedAccounts()
    },
    deleteItem(item, index) {
      if (item.isNew) {
        const itemIndex = this.innerValue.items
          .map(function(obj) {
            return obj.invoiceItemId
          })
          .indexOf(item.invoiceItemId)
        if (itemIndex >= 0) {
          this.innerValue.items.splice(itemIndex, 1)
        }
      } else {
        item.isDeleted = true
      }
      // recalculate the gstItem value
      this.innerValue.gst = this.colGst
      this.innerValue.totalExGst = this.totalExGst
      this.innerValue.totalIncGst = this.totalIncGst

      if (!this.innerValue.gstItem.isDeleted)
      {
        this.innerValue.gstItem.newValue = this.colGst === 0 ? this.innerValue.gstItem.unitPrice : roundAwayFromZero(this.innerValue.gstItem.unitPrice + this.colGst, 2)
        this.innerValue.gstItem.gst = calcGst(1, this.colGst, this.innerValue.gstRate)
        this.innerValue.gstItem.totalExGst = this.colGst
        this.innerValue.gstItem.totalIncGst = roundAwayFromZero(this.innerValue.gstItem.totalExGst + this.innerValue.gstItem.gst, 2)
      }
    },
    generateSelectedAccounts() {
      const vm = this
      vm.selectedItemAccounts = []
      this.innerValue.items.filter(i => !i.isDeleted).forEach(function(item) {
        vm.selectedItemAccounts.push(vm.glAccountListCombo.find(g => g.accountNo === item.invoiceGlCode))
      })
    },
    updateInnerValue() {
      this.innerValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.small-font {
  font-size: 18px;
  font-weight: bold;
}
</style>
