<template>
  <div>
    <div class="field">
      <label class="label">GST Invoice <span> {{ innerValue.adjustGstInvoiceNo }} </span></label>
    </div>
    <table class="table is-bordered is-striped is-narrow is-fullwidth">
      <thead>
        <tr>
          <th>Account</th>
          <th class="has-text-right">Amount</th>
          <th class="has-text-right">New Value</th>
          <th class="has-text-right">Adjust Value</th>
          <th>Remark</th>
        </tr>
      </thead>
      <tfoot>
        <tr>
          <th class="has-text-left">GST</th>
          <th colspan="4"
            class="has-text-left">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th>
        </tr>
      </tfoot>
      <tbody v-if="gstPaid || readOnlyView">
        <tr>
          <td>{{ innerValue.gstItem.glAccountName }}</td>
          <td class="has-vertical-middle has-text-right">{{ innerValue.gstItem.unitPrice | formatNumber($userInfo.locale) }}</td>
          <td class="has-vertical-middle has-text-right">
            {{ innerValue.gstItem.newValue | formatNumber($userInfo.locale) }}
          </td>
          <td class="has-vertical-middle has-text-right">
            {{ innerValue.gstItem.totalExGst | formatNumber($userInfo.locale) }}
          </td>
          <td>{{ innerValue.gstItem.invoiceItemDesc }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="has-vertical-middle">{{ innerValue.gstItem.glAccountName }}</td>
          <td class="has-vertical-middle has-text-right">{{ innerValue.gstItem.unitPrice | formatNumber($userInfo.locale) }}</td>
          <td v-if="!gstPaid"
            class="has-text-right money-input">
            <vue-numeric
              class="input has-text-right"
              v-model.number="innerValue.gstItem.newValue"
              :min="0"
              :precision="2"
              @input="updateNewValue(innerValue.gstItem)" />
          </td>
          <td v-else
            class="has-vertical-middle has-text-right">
            {{ innerValue.gstItem.newValue | formatNumber($userInfo.locale) }}
          </td>
          <td v-if="!gstPaid"
            class="has-text-right money-input">
            <vue-numeric
              class="input has-text-right"
              v-model.number="innerValue.gstItem.totalExGst"
              :minus="true"
              :precision="2"
              @input="updateAdjustValue(innerValue.gstItem)" />
          </td>
          <td v-else
            class="has-vertical-middle has-text-right">
            {{ innerValue.gstItem.totalExGst | formatNumber($userInfo.locale) }}
          </td>
          <td>
            <input class="input"
              v-model="innerValue.gstItem.invoiceItemDesc"
              type="text"
              placeholder="Remark">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { calcGst, roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import VueNumeric from '@/components/VueNumeric'
import { EventHubTypes } from '@/enums'

export default {
  name: 'DebtorAdjustmentGstInvoice',
  components: {
    VueNumeric
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    gstPaid: {
      type: Boolean,
      default: false
    },
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value)
    }
  },
  computed: { },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    'innerValue.gstItem': {
      // wacth to update the value in DebtorAdjustmentInvoice
      handler: function(newVal, oldVal) {
        this.innerValue.gst = this.innerValue.gstItem.totalExGst
        this.innerValue.totalIncGst = roundAwayFromZero(this.innerValue.gst + this.innerValue.totalExGst, 2)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.ItemValueChanged)
  },
  created() {
    this.$eventHub.$on(EventHubTypes.ItemValueChanged, () => {
      this.$nextTick(() => {
        this.innerValue = this.value
      })
    })
  },
  mounted() {},
  methods: {
    updateNewValue: _debounce(function(item) {
      this.innerValue.gstItem.totalExGst = roundAwayFromZero(item.newValue - item.unitPrice, 2)
      this.innerValue.gstItem.gst = calcGst(1, item.totalExGst, this.innerValue.gstRate)
      this.innerValue.gstItem.totalIncGst = roundAwayFromZero(item.totalExGst + item.gst, 2)
    }, 300),
    updateAdjustValue: _debounce(function(item) {
      this.innerValue.gstItem.newValue = roundAwayFromZero(item.unitPrice + item.totalExGst, 2)
      this.innerValue.gstItem.gst = calcGst(1, item.totalExGst, this.innerValue.gstRate)
      this.innerValue.gstItem.totalIncGst = roundAwayFromZero(item.totalExGst + item.gst, 2)
    }, 300),
    updateInnerValue() {
      this.innerValue = this.value
    }
  }
}
</script>
