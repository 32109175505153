<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <debtor-adjustment-invoice
            v-model="innerValue"
            :gst-paid="gstPaid"
            ref="invoicechild" />
          <debtor-adjustment-gst-invoice
            v-show="isAdjustGstInvoiceActive"
            v-model="innerValue"
            ref="gstchild"
            :gst-paid="gstPaid"
            :read-only-view="readOnlyView" />
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { FocusInserted } from '@/components/directives'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { InvoiceTypes } from '@/enums'
import { EventHubTypes } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'
import DebtorAdjustmentRoutes from './route-types'
import DebtorAdjustmentInvoice from './DebtorAdjustmentInvoice'
import DebtorAdjustmentGstInvoice from './DebtorAdjustmentGstInvoice'

export default {
  name: 'DebtorAdjustmentRevenueTotals',
  inject: ['$vv'],
  components: {
    DebtorAdjustmentInvoice,
    DebtorAdjustmentGstInvoice
  },
  directives: {
    FocusInserted
  },
  mixins: [NumberFiltersMixin],
  props: {
    value: null,
    gstPaid: {
      type: Boolean,
      default: false
    },
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      availableGlAccounts: [],
      isAdjustGstInvoiceActive: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  async created() {
    if (this.innerValue.applyToInvoiceType === InvoiceTypes.Gst || (this.innerValue.hasGstInvoice && !this.innerValue.gstItem.isDeleted))
    {
      this.isAdjustGstInvoiceActive = true
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
      this.$nextTick(() => {
        this.$refs.invoicechild.updateInnerValue()
        this.$refs.gstchild.updateInnerValue()
      })
    })

    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.$router.push({
        name: DebtorAdjustmentRoutes.DebtorAdjustmentDetail.name,
        params: { invoiceId: this.innerValue.invoiceId }
      })
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.money-input {
  max-width: 6.5em;
}
</style>
